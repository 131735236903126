import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import Card from '../../components/common/Card';
import EventList from '../../components/EventList';
import SectionHeader from '../../components/SectionHeader';
import { graphql, useStaticQuery, Link, navigate } from 'gatsby';
import { Event } from '../../components/Event';
import ContentContainer from '../../components/common/ContentContainer';
import Img from 'gatsby-image';
import SEO from '../../components/Seo';
import Button from '../../components/common/Button';
import { getEventSections, getGrades } from '../../components/members/api';
import { FormField, FormWrapper } from '../../components/common/Form';
import { useFormik } from 'formik';
import { transformGradeString } from '../../util/grades';
import { getEventSectionType } from '../../helpers/eventSections.helper';
import Selection from '../../components/common/Selection';
import { startOfDay } from 'date-fns';

enum EventQueryParamsFilter {
  search = 'search',
  grades = 'grades',
  location = 'location',
  institute = 'institute',
  oneDayConference = 'oneDayConference',
  seminarSeries = 'seminarSeries'
}

const EventQueryParamsFilterArray = [
  EventQueryParamsFilter.search,
  EventQueryParamsFilter.grades,
  EventQueryParamsFilter.location,
  EventQueryParamsFilter.institute,
  EventQueryParamsFilter.oneDayConference,
  EventQueryParamsFilter.seminarSeries
];

const EventQueryParamsFilterDefault = {
  [EventQueryParamsFilter.institute]: 'true',
  [EventQueryParamsFilter.oneDayConference]: 'true',
  [EventQueryParamsFilter.seminarSeries]: 'true',
  [EventQueryParamsFilter.location]: 'all'
};

export enum EventTypes {
  INSTITUTE = 'Institute',
  ONE_DAY_CONFERENCE = 'One-Day Conference',
  SEMINAR_SERIES = 'Seminar Series'
}

const Events: React.FC = () => {
  const updateQueryParams = (params: URLSearchParams) => {
    navigate(`?${params.toString()}`, { replace: true });
  };

  const locationOptions = [
    { label: 'All', value: 'all' },
    { label: 'Remote', value: 'remote' },
    { label: 'In-Person', value: 'in-person' }
  ];

  const updateFilter = (data: any) => {
    const params = new URLSearchParams();

    Object.keys(data).forEach(key => {
      if (EventQueryParamsFilterArray.some(p => p === key)) {
        if (key === 'grades' && data[key]?.length) {
          params.set(key, data[key]);
        } else if (data[key]) {
          params.set(key, data[key]);
        }
      }
    });
    updateQueryParams(params);
  };

  const formik = useFormik<any>({
    initialValues: {},
    onSubmit: () => {}
  });

  const mapQueryParamsToForm = (params: URLSearchParams) => {
    const form: any = {};
    params.forEach((value, key) => {
      if (
        [
          EventQueryParamsFilter.institute,
          EventQueryParamsFilter.oneDayConference,
          EventQueryParamsFilter.seminarSeries
        ].includes(key as EventQueryParamsFilter)
      ) {
        form[key] = value === 'true';
      } else if (key === EventQueryParamsFilter.grades) {
        form[key] = value ? value.split(',') : [];
      } else {
        form[key] = value;
      }
    });
    return form;
  };

  const resetFiltersToDefault = () => {
    formik.setValues({
      search: '',
      grades: [],
      location: locationOptions[0].value,
      institute: true,
      oneDayConference: true,
      seminarSeries: true
    });
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (EventQueryParamsFilterArray.some(p => params.has(p))) {
      formik.setValues(mapQueryParamsToForm(params));
    } else {
      resetFiltersToDefault();
    }
  }, []);

  const [totalEvents, setTotalEvents] = useState(0);
  const [eventSections, setEventSections] = useState([]);
  const [gradeOptions, setGradeOptions] = useState([]);
  const [formattedEvents, setFormattedEvents] = useState<any[]>([]);

  const data = useStaticQuery(graphql`
    {
      aboutEvents: file(relativePath: { eq: "images/events-1.jpg" }) {
        childImageSharp {
          sizes(maxWidth: 600) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      allContentfulEvent(
        sort: { fields: startDate, order: ASC }
        filter: { node_locale: { eq: "en-US" } }
      ) {
        edges {
          node {
            title
            type
            startDate
            grades
            location
            endDate
            description {
              description
            }
            slug
            price
          }
        }
      }
    }
  `);

  useEffect(() => {
    getEventSections().then(result => {
      setEventSections(result.eventSections);
    });
    getGrades().then(result => {
      setGradeOptions(
        result.grades?.map((g: any) => ({
          label: g.name,
          value: g.name
        })) || []
      );
    });
  }, []);

  const getEventSectionSetting = (section: any) => {
    const location = section?.location?.name?.toLowerCase();
    return location?.includes('via zoom') || location?.includes('remote')
      ? 'remote'
      : 'in-person';
  };

  const getEventType = (data: any, isContentful = false) => {
    if (isContentful) {
      switch (data.type) {
        case 'Saturday Reunion':
        case 'Seminar Series':
          return EventTypes.SEMINAR_SERIES;
        case 'Institute':
          return EventTypes.INSTITUTE;
        case 'One-Day Conference':
          return EventTypes.ONE_DAY_CONFERENCE;
        default:
          '';
      }
    } else {
      const eventType = data?.group?.eventPath?.event?.eventType?.name;
      switch (eventType) {
        case 'Series':
        case 'Seminar':
          return EventTypes.SEMINAR_SERIES;
        case 'Institute':
          return EventTypes.INSTITUTE;
        case 'Conference Day':
          return EventTypes.ONE_DAY_CONFERENCE;
        default:
          '';
      }
    }
  };

  const getAllEvents = () => {
    return [
      ...data.allContentfulEvent.edges
        .map((event: any) => event.node)
        .map((event: Event) => {
          return {
            ...event,
            type: 'EVENT',
            setting:
              event?.location?.toLowerCase().includes('via zoom') ||
              event?.location?.toLowerCase().includes('remote')
                ? 'remote'
                : 'in-person',
            eventType: getEventType(event, true),
            grades: transformGradeString(event.grades),
            startDate: new Date(event.startDate),
            endDate: new Date(event.endDate),
            desc: event.description?.description
          };
        }),
      ...(eventSections || []).map((section: any) => ({
        type: getEventSectionType(section),
        id: section.id,
        setting: getEventSectionSetting(section),
        eventType: getEventType(section),
        grades: section.audienceType,
        startDate: section.dates[0] ? new Date(section.dates[0]) : '',
        endDate: section.dates[0] ? new Date(section.dates[0]) : '',
        title: section.displayTitle || section.title,
        desc: section.description
      }))
    ].filter((e: any) => new Date(e.endDate) >= startOfDay(new Date()));
  };

  useEffect(() => {
    // @ts-ignore
    setFormattedEvents(getAllEvents());
    setTotalEvents(getAllEvents().length);
  }, [data, eventSections]);

  useEffect(() => {
    let events = getAllEvents();
    updateFilter(formik.values);
    const {
      search,
      grades,
      location,
      institute,
      oneDayConference,
      seminarSeries
    } = formik.values;
    if (search) {
      events = events.filter(
        event =>
          event.title.toLowerCase().includes(search.toLowerCase()) ||
          event.desc.toLowerCase().includes(search.toLowerCase())
      );
    }
    if (grades?.length) {
      events = events.filter(event =>
        grades.some((g: string) => event.grades.includes(g))
      );
    }
    if (location !== 'all') {
      events = events.filter(event => event.setting === location);
    }
    const eventTypesToInclude = [
      (institute && EventTypes.INSTITUTE) || '',
      (oneDayConference && EventTypes.ONE_DAY_CONFERENCE) || '',
      (seminarSeries && EventTypes.SEMINAR_SERIES) || ''
    ];
    events = events.filter(event =>
      eventTypesToInclude.includes(event.eventType)
    );

    setFormattedEvents(events);
  }, [formik.values, eventSections, data]);

  const isFilterApplied = () => {
    if (
      formik.values.search ||
      formik.values.grades?.length ||
      formik.values.location !== 'all'
    ) {
      if (
        !formik.values.institute &&
        !formik.values.oneDayConference &&
        !formik.values.seminarSeries
      ) {
        return false;
      }
      return true;
    }
  };
  return (
    <Layout>
      <SEO title="Events" />
      <ContentContainer>
        <SectionHeader header="Events" />
        <FormWrapper formik={formik} hideActionButtons={true}>
          <div className=" flex flex-row items-end mt-8">
            <FormField
              name="search"
              type="text"
              className="w-1/5 mr-2"
              placeholder="Search Events"
              label="Search Events"
            />
            <FormField
              name="grades"
              className="w-1/5 mr-2"
              type="select"
              isMulti
              options={gradeOptions}
            />

            <FormField
              name="location"
              className="w-1/5"
              type="select"
              options={locationOptions}
            />
            <Button
              text="Reset filters"
              type="button"
              size="sm"
              color="cancel"
              onClick={() => resetFiltersToDefault()}
              className="ml-2 mb-2"
            />
          </div>
          <div className="flex w-full gap-2 mt-2">
            {[
              {
                label: 'Institute',
                value: 'institute',
                selected: formik.values.institute
              },
              {
                label: 'One-Day Conference',
                value: 'oneDayConference',
                selected: formik.values.oneDayConference
              },
              {
                label: 'Seminar Series',
                value: 'seminarSeries',
                selected: formik.values.seminarSeries
              }
            ].map((c, i: number) => (
              <div key={i} className="w-1/3 sm:w-1/5">
                <Selection
                  item={c}
                  setSelected={() =>
                    formik.setFieldValue(c.value, !formik.values[c.value])
                  }
                />
              </div>
            ))}
          </div>
        </FormWrapper>
        {isFilterApplied() && (
          <div className="mt-4 sans-serif">
            Showing {formattedEvents?.length} of {totalEvents} results{' '}
            {formik.values.search && `for "${formik.values.search}"`}
          </div>
        )}
        <div className="mt-4">
          <Link to="/events/faq">
            <p className="text-primary hover:underline">Event FAQs</p>
          </Link>
        </div>
        <div className="flex mt-10">
          <div className="w-full">
            <Card>
              {formattedEvents.length ? (
                <EventList events={formattedEvents} />
              ) : (
                <div className="text-center text-gray-500">No events found</div>
              )}
            </Card>
          </div>
        </div>
      </ContentContainer>
    </Layout>
  );
};

export default Events;
